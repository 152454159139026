import React from "react"
import Topic from "../../components/Topic"
import { Link } from "gatsby"

const FinancialAid = () => (
  <Topic id="financial_aid">
    <p>
      Canada welcomes thousands of refugees each year. Both the federal and
      provincial governments offer some financial benefits to refugees and
      refugee claimants. One of the first steps is to{" "}
      <strong>get a Social Insurance Number (SIN)</strong> for each family
      member. <strong>Filing a tax return every year</strong> (regardless of
      whether you have income or not) is also an important step to ensure that
      you continue to receive any benefit and credit payments you might be
      eligible for. Please refer to the{" "}
      <Link to="/settling/finance/">Finance</Link> section for filing tax
      returns and the <Link to="/settling/government/">Government</Link> section
      for SIN.
    </p>
    <p>
      The two &ldquo;Benefit Finders&rdquo; included in the links on this page
      are helpful tools to discover what kinds of benefits you might qualify
      for. There are specific benefits for families with children under the age
      of 18. There are also tax credits; provincial and territorial programs;
      disability benefits; employment insurance; income supplements and many
      benefits you might qualify for. Take the time to discover which financial
      benefits are available to you.
    </p>
  </Topic>
)

export default FinancialAid
